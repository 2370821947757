import React from 'react'
import Name from './general/name'

const Onboard = () => {
  return (
    <div>
        <Name />
    </div>
  )
}

export default Onboard