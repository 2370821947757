import React, { useState } from 'react'
import "./login.css"
import OtpInput from "react-otp-input"
import { useNavigate } from "react-router-dom"

// icon
import { LuEye } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";
import { SiTicktick } from "react-icons/si";



const Login = () => {

    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [password, setPassword] = useState("")
    const [otp, setOtp] = useState("")
    const [passValidation, setPassValidation] = useState({
        length: false,
        number: false,
        specialCharacter: false
    })
    const [signUpEmail, setSignUpEmail] = useState("")
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [hideSignUpEmail, setHideSignUpEmail] = useState(true)
    const [hideSignUpNumber, setHideSignUpNumber] = useState(false)
    const [showEmailInput, setShowEmailInput] = useState(true)
    const [showEmailOtp, setShowEmailOtp] = useState(false)
    const [showMobileInput, setShowMobileInput] = useState(false)
    const [showMobileOtp, setShowMobileOtp] = useState(false)
    const [showEmailForm, setShowEmailForm] = useState(false)
    const [signUpNumber, setSignUpNumber] = useState(false)
    const [showEmailOtpNumber, setShowEmailOtpNumber] = useState(false)

    const handleSignUpEmailInput = (e) => {
        const { value } = e.target;
        setSignUpEmail(value);

        if (!isNaN(value) && value.length > 3) {
            setHideSignUpNumber(true);
            setHideSignUpEmail(false);
        } else {
            setHideSignUpNumber(false);
            setHideSignUpEmail(true);
        }
    }

    const handleContinue = (e) => {
        e.preventDefault();
        setShowEmailInput(false)
        setShowPasswordForm(true);
        setShowEmailOtp(false)
        setShowMobileInput(false)    
    };

    const handleChangeEmailOtp = (e) => {
        e.preventDefault()
        setShowEmailInput(false)
        setShowPasswordForm(false);
        setShowEmailOtp(true)
        setShowMobileInput(false)    
    }

    const handleChangeMobile = (e) => {
        e.preventDefault()
        setShowEmailInput(false)
        setShowPasswordForm(false);
        setShowEmailOtp(false)
        setShowMobileInput(true)    
    }

    const handleChangeMobileOtp = (e) => {
        e.preventDefault()
        setShowEmailInput(false)
        setShowPasswordForm(false);
        setShowEmailOtp(false)
        setShowMobileInput(false)
        setShowMobileOtp(true) 
    }

    const handleChangeOnboard = (e) => {
        e.preventDefault()
        setShowEmailInput(false)
        setShowPasswordForm(false);
        setShowEmailOtp(false)
        setShowMobileInput(false)

        setTimeout(()=>{
            navigate("/onboarding")
        },1000)
         
    }

    const handleMobileOtp = (e) => {
        e.preventDefault()
        setShowEmailInput(false)
        setSignUpNumber(true)
    }

    const handleEmailInput = (e) => {
        e.preventDefault()
        setShowEmailInput(false)
        setSignUpNumber(false)
        setShowEmailForm(true)
    }

    const handleNumberEmailOtp = (e) =>{
        e.preventDefault()
        setShowEmailInput(false)
        setSignUpNumber(false)
        setShowEmailForm(false)
        setShowEmailInput(false)
        setShowEmailOtpNumber(true)
    }

    const handleNumberOnboarding = (e) => {
        e.preventDefault()
        setShowEmailInput(false)
        setSignUpNumber(false)
        setShowEmailForm(false)
        setShowEmailInput(false)
        setShowEmailOtpNumber(true)
        setTimeout(()=>{
            navigate("/onboarding")
        },1000)
    }

    const validatePassword = (password) => {
        const length = password.length >= 8
        const number = /[0-9]/.test(password)
        const specialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password)

        setPassValidation({length, number, specialCharacter})
    }

    const handleInputChange = (e) => {
        const newPass = e.target.value
        setPassword(newPass)
        validatePassword(newPass)
    }

    const handleShow = (e) => {
        e.preventDefault()
        setShow(!show)
    }

  return (
    <div className='login__background p-8 flex justify-center lg:justify-between items-center'>
        <div className='hidden lg:inline-block lg:w-8/12 xl:w-1/2'>
           <div className='haodapay__logoLeft'>
                <img src='/images/haodaLogo.png' alt='img' />
            </div>
            <div className='w-full h-full grid place-content-center login__container'>
                <span className='login__contntTitle block'>Be part of the 5 million businesses that trust HaodaPay to elevate their performance.</span>
                <div className='flex flex-wrap gap-6 mt-5'>
                    <div className='flex items-center'>
                       <SiTicktick className='mr-3' /> <span className='login__BaseText'>100+ Payment Methods</span>
                    </div>
                    <div className='flex items-center'>
                       <SiTicktick className='mr-3' /> <span className='login__BaseText'>Simple Integration</span>
                    </div>
                    <div className='flex items-center'>
                       <SiTicktick className='mr-3' /> <span className='login__BaseText'>Feature-rich Dashboard</span>
                    </div>
                </div>
            </div>
        </div>

    {/* Email input */}

    {showEmailInput && (
     <div className='login__content h-full pb-10 px-5 sm:px-16 lg:px-5 xl:px-16 flex justify-center items-center flex-col'>
        <div>
          <div className='haodapay__logo mt-15'>
            <img src='/images/haoda logo png.png' alt='img' />
          </div>
          <span className='block login__title mb-8'>Welcome to <span className='haodaTitle'>Haoda</span> <span className='payTitle'>Payments</span></span>
          <span className='block login__para'>Get started with your email or phone number</span>
          
          { hideSignUpEmail && (
          <form onSubmit={handleContinue}>
            <input
              type='text'
              name='signEmail'
              className='login__input mt-8 mb-10 px-5'
              value={signUpEmail}
              placeholder='Enter your email or phone number'
              onChange={handleSignUpEmailInput}
            />
            <button className='login__btn' type='submit'>Continue</button>
          </form> )}

         {hideSignUpNumber && (
           <form onSubmit={handleMobileOtp}>
               <div className='flex gap-x-3 mt-8'>
                 <input type='text' placeholder='+91' readOnly className='input__num' />
                 <input type='text'  className='input__enterNum' placeholder='Enter your mobile number' />
               </div>
               <button className='login__btn mt-10' type='submit'>Send OTP</button>
           </form>  )}

          <div className='flex gap-2 mt-5'>
            <span className='block login__footerContent'>Terms of use |</span>
            <span className='block login__footerContent'>Privacy policy |</span>
            <span className='block login__footerContent'>Support</span>
          </div>
        </div>
    </div>
    )}


    {/* Welcome password login */}

        {/* <div className='login__content  px-5 sm:px-16 lg:px-5 xl:px-16 flex justify-center flex-col'>
            <span className='block login__para'>Welcome back. Enter your password to login</span>
            <form>
                <input type='password' className='login__input mt-8 mb-10 px-5' placeholder='Enter password' />
                <button className='login__btn'>Continue</button>
            </form>
            <div className='flex gap-2 mt-5'>
                <span className='block login__footerContent'>Terms of use |</span>
                <span className='block login__footerContent'>Privacy policy |</span>
                <span className='block login__footerContent'>Support</span>
            </div>
        </div> */}
    
    {/* Create Your Password */}

    {showPasswordForm &&  (
        <div className='login__content px-5 sm:px-16 lg:px-5 xl:px-16 flex justify-center flex-col'>
          <div className='haodapay__logo mt-15'>
            <img src='/images/haoda logo png.png' alt='img' />
          </div>          
          <span className='block createPass__title mb-8'>Create your password</span>
          <form onSubmit={handleChangeEmailOtp}>
            <div className='createPass__input flex items-center'>
              <input 
                type={show ? "text" : "password"} 
                value={password} 
                onChange={handleInputChange}  
                placeholder='Enter password' 
              />
              {show ? (
                <button onClick={handleShow}><LuEye className='LuEye' /></button>
              ) : (
                <button onClick={handleShow}> <LuEyeOff className='LuEye'/></button>
              )}
            </div>
            <div className='mt-5'>
              <div className={passValidation.length ? "valid" : "invalid"}>
                <SiTicktick className='inline-block SiTicktick mr-2' /> <span className='SiTicktick'>Must include 8 characters</span>
              </div>
              <div className={passValidation.number ? "valid" : "invalid"}>
                <SiTicktick className='inline-block mr-2 SiTicktick my-3'/> <span className='SiTicktick'>Must include a number</span>
              </div>
              <div className={passValidation.specialCharacter ? "valid" : "invalid"}>
                <SiTicktick className='inline-block mr-2 SiTicktick'/> <span className='SiTicktick'>Must include a special character</span>
              </div>
            </div>
            <button className='login__btn mt-10' type='submit'>Continue</button>
            <div className='flex gap-2 mt-5'>
              <span className='block login__footerContent'>Terms of use |</span>
              <span className='block login__footerContent'>Privacy policy |</span>
              <span className='block login__footerContent'>Support</span>
            </div>
          </form>
        </div>
      )}  

    {/* Email Otp */}

    { showEmailOtp &&  (

        <div className='login__content px-5 sm:px-16 lg:px-5 xl:px-16 flex justify-center flex-col'>
          <div className='haodapay__logo mt-15'>
            <img src='/images/haoda logo png.png' alt='img' />
          </div>

           <span className='block createPass__title mb-8'>Enter the code sent to your mail</span>
            <form onSubmit={handleChangeMobile}>
                <OtpInput
                  value={otp}
                  inputType="password"
                  numInputs={6}
                  onChange={setOtp}
                  inputStyle={{width:"60px", height:"60px", border:"1px solid #2A2A2ACC", borderRadius:"4px", outline:"none"}}
                  containerStyle={{display:"flex", alignItems:"center", gap:"20px", flexWrap:"wrap"}}
                  renderInput={(props) => <input {...props} />}
                />
                <div className='mt-5'>
                    <button className='resend__otpBtn flex ml-auto'>Resend OTP in 10s</button>
                </div>                  
                <button className='login__btn mt-10' type='submit'>Verify</button>
            </form>
            <div className='flex gap-2 mt-5'>
                <span className='block login__footerContent'>Terms of use |</span>
                <span className='block login__footerContent'>Privacy policy |</span>
                <span className='block login__footerContent'>Support</span>
            </div>
        </div> )}

    {/* Phone Number Otp */}

    { signUpNumber && (
    
        <div className='login__content px-5 sm:px-16 lg:px-5 xl:px-16 flex justify-center flex-col'>
           <div className='haodapay__logo mt-15'>
               <img src='/images/haoda logo png.png' alt='img' />
           </div>
           <span className='block createPass__title mb-8'>Enter the code sent to your phone number</span>
            <form onSubmit={handleEmailInput}>
                <OtpInput
                  value={otp}
                  inputType="password"
                  numInputs={6}
                  onChange={setOtp}
                  inputStyle={{width:"60px", height:"60px", border:"1px solid #2A2A2ACC", borderRadius:"4px", outline:"none"}}
                  containerStyle={{display:"flex",justifyContent:"center", alignItems:"center", gap:"20px", flexWrap:"wrap"}}
                  renderInput={(props) => <input {...props} />}
                />
                <div className='mt-5'>
                    <button className='resend__otpBtn flex ml-auto'>Resend OTP in 10s</button>
                </div>                  
                <button className='login__btn mt-10' type='submit'>Verify</button>
            </form>
            <div className='flex gap-2 mt-5'>
                <span className='block login__footerContent'>Terms of use |</span>
                <span className='block login__footerContent'>Privacy policy |</span>
                <span className='block login__footerContent'>Support</span>
            </div>
        </div> )}

    {/* Enter Phone number */}

    {showMobileInput && (

        <div className='login__content px-5 sm:px-16 lg:px-5 xl:px-16 flex justify-center flex-col'>
            <div className='haodapay__logo mt-15'>
            <img src='/images/haoda logo png.png' alt='img' />
           </div>
           <span className='block createPass__title mb-8'>What’s your mobile number?</span>
           <form onSubmit={handleChangeMobileOtp}>
              <div className='flex gap-x-3'>
                <input type='text' placeholder='+91' readOnly className='input__num' />
                <input type='text'  className='input__enterNum' placeholder='Enter your mobile number' />
              </div>
              <button className='login__btn mt-10' type='submit'>Send OTP</button>
           </form>  
           <div className='flex gap-2 mt-5'>
                <span className='block login__footerContent'>Terms of use |</span>
                <span className='block login__footerContent'>Privacy policy |</span>
                <span className='block login__footerContent'>Support</span>
            </div>
        </div>  )} 


    {/* Enter Phone number */}

        {/* <div className='login__content px-5 sm:px-16 lg:px-5 xl:px-16 flex justify-center flex-col'>
           <span className='block createPass__title mb-8'>What’s your email?</span>
           <form>
              <div className='flex gap-x-3'>
                <input type='text'  className='input__enterNum' placeholder='Enter your email' />
              </div>
              <button className='login__btn mt-10'>Continue</button>
           </form> 
           <div className='flex gap-2 mt-5'>
               <span className='block login__footerContent'>Terms of use |</span>
               <span className='block login__footerContent'>Privacy policy |</span>
               <span className='block login__footerContent'>Support</span>
            </div> 
        </div>  */}

    {/* Enter Phone number */}

    {showMobileOtp && (

        <div className='login__content px-5 sm:px-16 lg:px-5 xl:px-16 flex justify-center flex-col'>
            <div className='haodapay__logo mt-15'>
            <img src='/images/haoda logo png.png' alt='img' />
            </div>
           <span className='block createPass__title mb-8'>Enter the OTP</span>
           <form onSubmit={handleChangeOnboard}>
                <OtpInput
                  value={otp}
                  inputType="password"
                  numInputs={6}
                  inputStyle={{width:"60px", height:"60px", border:"1px solid #2A2A2ACC", borderRadius:"4px", outline:"none"}}
                  containerStyle={{display:"flex",justifyContent:"center", alignItems:"center", gap:"20px", flexWrap:"wrap"}}
                  renderInput={(props) => <input {...props} />}
                />
                <div className='mt-5'>
                    <button className='resend__otpBtn flex ml-auto'>Resend OTP in 10s</button>
                </div>                  
                <button className='login__btn mt-10' type='submit'>Submit</button>
            </form> 
            <div className='flex gap-2 mt-5'>
                <span className='block login__footerContent'>Terms of use |</span>
                <span className='block login__footerContent'>Privacy policy |</span>
                <span className='block login__footerContent'>Support</span>
            </div>
        </div> )} 

{/* Email After mobile number */}

    { showEmailForm && (
        <div className='login__content px-5 sm:px-16 lg:px-5 xl:px-16 flex justify-center flex-col'>
            <div className='haodapay__logo mt-15'>
            <img src='/images/haoda logo png.png' alt='img' />
           </div>
           <span className='block createPass__title mb-8'>What’s your email?</span>
           <form onSubmit={handleNumberEmailOtp}>
            <input
              type='text'
              name='signEmail'
              className='login__input mb-10 px-5'
              placeholder='Enter your email or phone number'
            />
            <button className='login__btn' type='submit'>Continue</button>
          </form>
           <div className='flex gap-2 mt-5'>
               <span className='block login__footerContent'>Terms of use |</span>
               <span className='block login__footerContent'>Privacy policy |</span>
               <span className='block login__footerContent'>Support</span>
            </div> 
        </div> )}


{/* Enter Email Otp after number */}

    {showEmailOtpNumber && (

        <div className='login__content px-5 sm:px-16 lg:px-5 xl:px-16 flex justify-center flex-col'>
            <div className='haodapay__logo mt-15'>
            <img src='/images/haoda logo png.png' alt='img' />
           </div>
           <span className='block createPass__title mb-8'>Enter the OTP</span>
           <form onSubmit={handleNumberOnboarding}>
                <OtpInput
                  value={otp}
                  inputType="password"
                  numInputs={6}
                  inputStyle={{width:"60px", height:"60px", border:"1px solid #2A2A2ACC", borderRadius:"4px", outline:"none"}}
                  containerStyle={{display:"flex",justifyContent:"center", alignItems:"center", gap:"20px", flexWrap:"wrap"}}
                  renderInput={(props) => <input {...props} />}
                />
                <div className='mt-5'>
                    <button className='resend__otpBtn flex ml-auto'>Resend OTP in 10s</button>
                </div>                  
                <button className='login__btn mt-10' type='submit'>Submit</button>
            </form> 
            <div className='flex gap-2 mt-5'>
                <span className='block login__footerContent'>Terms of use |</span>
                <span className='block login__footerContent'>Privacy policy |</span>
                <span className='block login__footerContent'>Support</span>
            </div>
        </div> )} 

    </div>
  )
}

export default Login