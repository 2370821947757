import Login from "./component/login/login";
import { BrowserRouter, Routes, Router, Route } from "react-router-dom"
import Onboard from "./component/onboard/onboard";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/onboarding" element={<Onboard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
