import React, { useEffect, useState, useRef } from 'react';
import "./name.css";

// icon
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { IoIosArrowDropright } from "react-icons/io";


const Name = () => {
    const [currentSlider, setCurrentSlider] = useState(1);
    const [showName, setShowName] = useState(true);
    const [showWebSite, setShowWebsite] = useState(false);
    const sliderRef = useRef(null);

    const handleShowWebsite = (e) => {
        e.preventDefault();
        setShowName(false);
        setShowWebsite(true);
    };

    const totalSlider = 6;

    const pre = () => {
        if (currentSlider > 1) {
            setCurrentSlider(currentSlider - 1);
        }
    };

    const next = () => {
        if (currentSlider < totalSlider) {
            setCurrentSlider(currentSlider + 1);
        }
    };

    useEffect(() => {
        slideShow();
    }, [currentSlider]);

    const slideShow = () => {
        if (sliderRef.current) {
            const slide = sliderRef.current.getElementsByTagName("li");
            for (let index = 0; index < totalSlider; index++) {
                const element = slide[index];
                if (currentSlider === index + 1) {
                    element.classList.remove("hidden");
                } else {
                    element.classList.add("hidden");
                }
            }
        }
    };

    return (
        <div className='general__background py-8 px-8 lg:px-0'>
            <div className='container h-full mx-auto flex justify-center items-center'>
                <div className='haodapay__logoLeft_ hidden xl:inline-block'>
                    <img src='/images/haodaLogo.png' alt='img' />
                </div>

                {showName && (
                    <div className='general__information  flex justify-between flex-col sm:px-11 p-9'>
                        <div>
                            <span className='flex items-center back__title' onClick={pre}>
                                <IoChevronBackCircleOutline className='IoChevronBackCircleOutline mr-2' /> Back
                            </span>
                            <div className='mt-32'>
                                <div className='haodapay__logo mt-15'>
                                  <img src='/images/haoda logo png.png' alt='img' />
                                </div>
                                <form onSubmit={handleShowWebsite}>
                                    <span className='block general__name'>What's your name?</span>
                                    <input placeholder='Enter name' className='login__input px-5 mt-7 mb-10' />
                                    <button className='login__btn' type='submit'>Submit</button>
                                </form>
                            </div>
                        </div>
                        <div className='privacy__content mt-auto flex gap-2'>
                           <span className='block privacy__title'>Terms of use |</span>
                           <span className='block privacy__title'>Privacy policy |</span>
                           <span className='block privacy__title'>Support</span>
                        </div>
                    </div>
                )}

                {showWebSite && (
                    <div className='general__information px-11 p-9'>
                        <div className='w-full flex justify-between items-center'>
                            <span className='flex items-center back__title' onClick={pre}>
                                <IoChevronBackCircleOutline className='IoChevronBackCircleOutline mr-2' /> Back
                            </span>
                            <span className='flex items-center back__title' onClick={next}>
                               next <IoIosArrowDropright  className='IoChevronBackCircleOutline ml-2' />
                            </span>
                        </div>                        
                        <div className='flex justify-around flex-col h-full'>
                        <ul className='general__slides' id='slider' ref={sliderRef}>
                            <li className='hidden'>
                                <div className='haodapay__logo mt-15'>
                                  <img src='/images/haoda logo png.png' alt='img' />
                                </div>
                                <span className='block general__name'>Where do you want to use Haodapay for collecting payments?</span>
                                <form>
                                    <div className='collectPayment__form mt-7'>
                                        <div className='flex items-center checkbox__content py-6 mr-14'>
                                            <input type='checkbox' />
                                            <span className='checkbox__title ml-5'>on my website</span>
                                        </div>
                                        <div className='flex items-center checkbox__content py-6 mr-14'>
                                            <input type='checkbox' />
                                            <span className='checkbox__title ml-5'>on my android app</span>
                                        </div>
                                        <div className='flex items-center checkbox__content py-6 mr-14'>
                                            <input type='checkbox' />
                                            <span className='checkbox__title ml-5'>on my iOS app</span>
                                        </div>
                                        <div className='flex items-center checkbox__content py-6 mr-14'>
                                            <input type='checkbox' />
                                            <span className='checkbox__title ml-5'>on Whatsapp, Facebook and Instagram</span>
                                        </div>
                                        <div className='flex items-center checkbox__content py-6 mr-14'>
                                            <input type='checkbox' />
                                            <span className='checkbox__title ml-5'>on SMS or Email</span>
                                        </div>
                                        <div className='flex items-center checkbox__content py-6 mr-14'>
                                            <input type='checkbox' />
                                            <span className='checkbox__title ml-5'>Others</span>
                                        </div>
                                    </div>
                                    <div className='mt-5 mr-14'>
                                        <button className='login__btn'>Submit</button>
                                    </div>
                                </form>
                            </li>

                            <li className='hidden mt-10'>
                                <div className='haodapay__logo mt-15'>
                                  <img src='/images/haoda logo png.png' alt='img' />
                                </div>
                                <form>
                                    <span className='block general__name'>Add your website link</span>
                                    <input placeholder='Enter website link' className='login__input px-5 mt-7 mb-10' />
                                    <button className='login__btn'>Continue</button>
                                </form>
                            </li>

                            <li className='hidden'>
                                <div className='haodapay__logo mt-15'>
                                  <img src='/images/haoda logo png.png' alt='img' />
                                </div>
                                <span className='block general__name'>What’s your business type?</span>
                                <span className='block general__para'>Registered Business</span>
                                <form>
                                    <div className='collectPayment__form mt-7'>
                                        <div className='flex items-center checkbox__content py-6 mr-14'>
                                            <input type='checkbox' />
                                            <span className='checkbox__title ml-5'>LLP</span>
                                        </div>
                                        <div className='flex items-center checkbox__content py-6 mr-14'>
                                            <input type='checkbox' />
                                            <span className='checkbox__title ml-5'>Partnership</span>
                                        </div>
                                        <div className='flex items-center checkbox__content py-6 mr-14'>
                                            <input type='checkbox' />
                                            <span className='checkbox__title ml-5'>Private Limited</span>
                                        </div>
                                        <div className='flex items-center checkbox__content py-6 mr-14'>
                                            <input type='checkbox' />
                                            <span className='checkbox__title ml-5'>Proprietorship</span>
                                        </div>
                                        <div className='flex items-center checkbox__content py-6 mr-14'>
                                            <input type='checkbox' />
                                            <span className='checkbox__title ml-5'>Society/NGO</span>
                                        </div>
                                        <div className='flex items-center checkbox__content py-6 mr-14'>
                                            <input type='checkbox' />
                                            <span className='checkbox__title ml-5'>OPC</span>
                                        </div>
                                    </div>
                                    <div className='mt-5 mr-14'>
                                        <button className='login__btn'>Submit</button>
                                    </div>
                                </form>
                            </li>

                            <li className='hidden mt-10'>
                                <div className='haodapay__logo mt-15'>
                                  <img src='/images/haoda logo png.png' alt='img' />
                                </div>
                                <form>
                                    <span className='block general__name'>What’s your personal PAN number?</span>
                                    <input placeholder='Enter your PAN number' className='login__input px-5 mt-7 mb-10' />
                                    <button className='login__btn'>Continue</button>
                                </form>
                            </li>

                            <li className='hidden mt-10'>
                                <div className='haodapay__logo mt-15'>
                                 <img src='/images/haoda logo png.png' alt='img' />
                                </div>
                                <form>
                                    <span className='block general__name'>What’s the name on your PAN?</span>
                                    <input placeholder='Enter your PAN name' className='login__input px-5 mt-7 mb-10' />
                                    <button className='login__btn'>Continue</button>
                                </form>
                            </li>

                            <li className='hidden mt-10'>
                                <div className='haodapay__logo mt-15'>
                                 <img src='/images/haoda logo png.png' alt='img' />
                                </div>                                
                                <form>
                                    <span className='block general__name'>What’s your brand name?</span>
                                    <input placeholder='Enter brand name' className='login__input px-5 mt-7 mb-10' />
                                    <button className='login__btn'>Continue</button>
                                </form>
                            </li>

                        </ul>
                        <div className='privacy__content flex gap-2'>
                           <span className='block privacy__title'>Terms of use |</span>
                           <span className='block privacy__title'>Privacy policy |</span>
                           <span className='block privacy__title'>Support</span>
                        </div>
                        </div>
                    </div>
                )}

                <div className='h-full flex justify-end'>
                    
                </div>
            </div>
        </div>
    );
};

export default Name;
